import * as React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from 'classnames'
import {IData} from "../../reducers/dataReducer"
import {O_ID_Sort} from '../../tools/tools'

import Header from './Header'
import Privacy from './Privacy'
import {OLabel, ORow, OSubmit, OWarning} from './FormHelpers'
import {OButton} from "../buttons/OButtons"
import Topnav from "../buttons/Topnav"

import styles from './appointment.module.scss'

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { Form, FormGroup, Label, Input, Col } from 'reactstrap';

interface State {
  appointment: {
    name: string;
    surname: string;
    dateofbirth: string;
    street_and_nr: string;
    zipcode: string;
    city: string;
    email: string;
    phone: string;
    subject: string;
    doctor: string;
    diet: boolean;
    fysio: boolean;
  }
  show_warning: boolean;
}

interface parentProps {
  history: any;
  background: string;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Appointment extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      appointment: {
        name: "",
        surname: "",
        dateofbirth: "",
        street_and_nr: "",
        zipcode: "",
        city: "",
        email: "",
        phone: "",
        subject: "",
        doctor: "",
        diet: true,
        fysio: true,
      },
      show_warning: false,
    }
  }


  render() {
    return (
      <div className="ocontent">

        <Helmet>
          <title>Afspraak maken - Fitness Ten Boer</title>
          <meta name="description" content={`Afspraak maken - Fitness Ten Boer`} />
        </Helmet>

        <Topnav background={this.props.background}/>

        <div className={classNames(styles.formHolder, "row justify-content-md-center")}>
          <div className="col-12">
            {this.form()}
          </div>
        </div>
      </div>
      )
  }

  form() {
    const a = this.state.appointment
    return (
      <div className={classNames(styles.form, "container")}>

      <ORow title="Voornaam">
        <Input
              invalid={a.name.trim().length == 0}
              type="text"
              name="name"
              value={a.name}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Achternaam">
        <Input
              invalid={a.surname.trim().length == 0}
              type="text"
              name="surname"
              value={a.surname}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Geboortedatum (d/m/j)">
        <Input
              invalid={a.dateofbirth.trim().length == 0}
              type="text"
              name="dateofbirth"
              value={a.dateofbirth}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Straat + huisnummer">
        <Input
              invalid={a.street_and_nr.trim().length == 0}
              type="text"
              name="street_and_nr"
              value={a.street_and_nr}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Postcode">
        <Input
              invalid={a.zipcode.trim().length == 0}
              type="text"
              name="zipcode"
              value={a.zipcode}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Woonplaats">
        <Input
              invalid={a.city.trim().length == 0}
              type="text"
              name="city"
              value={a.city}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Email">
        <Input
              invalid={a.email.trim().length == 0}
              type="text"
              name="email"
              value={a.email}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>

      <ORow title="Telefoonnummer">
        <Input
              invalid={a.phone.trim().length == 0}
              type="text"
              name="phone"
              value={a.phone}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>


     <FormGroup row>
       <OLabel>Wenst u een afspraak met de diëtiste?</OLabel>
        <div className={classNames(styles.selectHolder, styles.selectHolderLeft, "col-6", "col-md-4")} onClick={() => this.setBoolean('diet', true)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass('diet', true))}>Ja</div>
        </div>
        <div className={classNames(styles.selectHolder, styles.selectHolderRight, "col-6", "col-md-4" )} onClick={() => this.setBoolean('diet', false)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass('diet', false))}>Nee</div>
        </div>
      </FormGroup>

     <FormGroup row>
       <OLabel>Wenst u een afspraak met de fysiotherapeut?</OLabel>
        <div className={classNames(styles.selectHolder, styles.selectHolderLeft, "col-6", "col-md-4")} onClick={() => this.setBoolean('fysio', true)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass('fysio', true))}>Ja</div>
        </div>
        <div className={classNames(styles.selectHolder, styles.selectHolderRight, "col-6", "col-md-4")} onClick={() => this.setBoolean('fysio', false)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass('fysio', false))}>Nee</div>
        </div>
      </FormGroup>




{/*       <ORow title="Huisarts">
        <Input
              invalid={a.doctor.trim().length == 0}
              type="text"
              name="doctor"
              value={a.doctor}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>*/}

{/*
      <ORow title="Lijdt u aan andere ziekten">
          <Input
              invalid={a.subject.trim().length == 0}
              type="textarea"
              name="subject"
              value={a.subject}
              onChange={(e) =>this.handleInputChange(e)}
          />
      </ORow>
*/}
{/*      <FormGroup row>
       <OLabel>Rookt u</OLabel>
        <div className={classNames(styles.selectHolder, styles.selectHolderLeft, "col-6", "col-sm-4")} onClick={() => this.setBoolean('smoke', true)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass('smoke', true))}>Ja</div>
        </div>
        <div className={classNames(styles.selectHolder, styles.selectHolderRight, "col-6", "col-sm-4")} onClick={() => this.setBoolean('smoke', false)}>
          <div className={classNames(styles.label, styles.selectButton, this.refclass('smoke', false))}>Nee</div>
        </div>
      </FormGroup>*/}


      {this.state.show_warning? <OWarning /> : ''}

      <OSubmit click={() => this.submit()} />

      <Privacy />

      </div>
    )
  }

  setBoolean(name, value) {
    let ap = {
      ...this.state.appointment,
    }

    ap[name] = value

    this.setState({
      appointment: ap,
    })
  }

  refclass(name, value) {
    if (this.state.appointment[name] === value)
      return styles.selected
    return styles.notSelected
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newstate = {
      ...this.state.appointment,
      [name]: value,
    }

    this.setState({
      appointment: {
        ...newstate,
      }
    });
  }

  validate() {
    const a = this.state.appointment
    return a.name.trim().length > 0 &&
    a.surname.trim().length > 0 &&
    a.dateofbirth.trim().length > 0 &&
    a.street_and_nr.trim().length > 0 &&
    a.zipcode.trim().length > 0 &&
    a.city.trim().length > 0 &&
    a.email.trim().length > 0 &&
    a.phone.trim().length > 0
    // a.subject.trim().length > 0 &&
    // a.doctor.trim().length > 0
  }

  submit() {
    if (!this.validate()){
      this.setState({
        show_warning: true
      })
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    let that = this

    axios.post("/contact/appointment",{
      appointment: this.state.appointment
    }, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }).then(function(result) {
      console.log("worked")
      that.props.history.push('/aanmelden_succes')
    }).catch(function (error) {
      if (error.response) {
        console.log('Error', error.message);
      }
    })
  }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointment));

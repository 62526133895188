import * as React from 'react';
import classNames from 'classnames'
import styles from './appointment.module.scss'
import {OButton} from "../buttons/OButtons"

export const OLabel = (props) => {
  return <div className={classNames(styles.label, "col-12 col-md-4", styles.ocol)}>
    {props.children}
  </div>
}

export const ORow = (props) => {
  return <div className="row form-group">
    <OLabel>{props.title}</OLabel>
    <div className={classNames(styles.inputHolder, "col-12","col-md-8")}>
      {props.children}
    </div>
  </div>
}

export const OSubmit = (props) => {
  return <div className="row form-group">
    <div className="col-4"></div>
    <div onClick={props.click} className={classNames(styles.inputHolder, "col-4")}>
      <div className={classNames(styles.label, styles.selectButton, styles.submit)}>Verstuur</div>
    </div>
  </div>
}

  export const OWarning = () => {
  return <div className="row form-group">
    <div className="col-4"></div>

    <div className={classNames(styles.warning, "col-12 col-md-8")}>
      Vul aub de verplichte velden in
    </div>
  </div>
}

// export const OHeader = (props) => {
//   return <div className="row justify-content-md-center">
//     <div className="col-12 col-md-10 col-lg-10 col-xl-9">
//       <div className={classNames(styles.navHolder, 'row')}>
//         <div className="col-12 col-md-4"></div>
//         <div className={classNames(styles.navButton, "col-12 col-md-8 col-lg-4")}>
//           <OButton target={props.to} title={props.title} extraclass={styles.appointment} />
//         </div>
//       </div>
//     </div>
//   </div>

// }

import * as React from "react";

export const SVGHamburger = (props) => {
  return <svg viewBox="0 0 55 55">
      <g id="hamburger" style={{fill: props.color}} stroke={props.color}>
        <rect className="line1" x="0" y="0" width="55" height="5"/>
        <rect className="line2" x="0" y="25" width="55" height="5"/>
        <rect className="line3" x="0" y="50" width="55" height="5"/>
      </g>
    </svg>
}

export function OLogo(props) {
  return <svg id="svg513" viewBox="0 0 78.559692 17.055475" height="17.055475mm" width="78.559692mm">
  <defs id="defs507" />
  <g
     transform="translate(-31.023723,-93.436551)"
     id="layer1">
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,31.837934,96.233491)"
       id="g34">
      <path
         id="path36"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="M 0,0 H -2.308 V 2.579 H 0 v 1.086 c 0,2.471 1.548,4.262 4.697,4.262 0.679,0 1.385,-0.081 2.037,-0.108 V 4.941 C 6.272,4.995 5.811,5.023 5.322,5.023 4.29,5.023 3.856,4.588 3.856,3.421 V 2.579 H 6.848 V 0 H 3.856 V -11.458 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,36.856934,100.89816)"
       id="g38">
      <path
         id="path40"
         style={{fill: props.color, fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c -0.842,-2.254 -2.172,-3.177 -4.833,-3.177 -0.787,0 -1.574,0.054 -2.362,0.136 V 0.136 C -6.462,0.082 -5.701,-0.027 -4.941,0 c 1.33,0.136 1.765,1.521 1.33,2.634 l -4.941,13.168 h 4.127 L -1.249,6.19 h 0.054 l 3.068,9.612 h 3.992 z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,40.123234,98.666421)"
       id="g42">
      <path
         id="path44"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
                  d="m 0,0 c 0.027,-1.684 1.439,-2.362 2.959,-2.362 1.113,0 2.525,0.434 2.525,1.792 0,1.167 -1.602,1.574 -4.371,2.172 -2.226,0.488 -4.453,1.275 -4.453,3.747 0,3.583 3.096,4.507 6.109,4.507 3.068,0 5.892,-1.032 6.19,-4.48 H 5.294 c -0.108,1.493 -1.249,1.9 -2.633,1.9 -0.869,0 -2.145,-0.162 -2.145,-1.303 0,-1.385 2.172,-1.575 4.371,-2.091 2.254,-0.515 4.453,-1.33 4.453,-3.936 0,-3.693 -3.204,-4.887 -6.408,-4.887 -3.258,0 -6.434,1.222 -6.598,4.941 z" />
    </g>
    <path
       id="path46"
       style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 0.35277775}}
       d="m 44.251054,94.557321 h 1.36031 v -1.12077 h -1.36031 z m 0,5.718179 h 1.36031 v -4.951939 h -1.36031 z" />
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,49.088064,95.189441)"
       id="g48">
      <path
         id="path50"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 4.426,0 7.276,-2.933 7.276,-7.413 0,-4.452 -2.85,-7.384 -7.276,-7.384 -4.398,0 -7.249,2.932 -7.249,7.384 C -7.249,-2.933 -4.398,0 0,0 m 0,-11.893 c 2.634,0 3.421,2.254 3.421,4.48 0,2.254 -0.787,4.508 -3.421,4.508 -2.606,0 -3.394,-2.254 -3.394,-4.508 0,-2.226 0.788,-4.48 3.394,-4.48" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,54.260354,95.323811)"
       id="g52">
      <path
         id="path54"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="M 0,0 H 2.824 V -2.579 H 0 v -6.95 c 0,-1.304 0.326,-1.63 1.629,-1.63 0.407,0 0.788,0.028 1.195,0.109 v -3.013 c -0.652,-0.109 -1.493,-0.136 -2.254,-0.136 -2.362,0 -4.425,0.543 -4.425,3.34 v 8.28 H -6.19 V 0 h 2.335 V 4.208 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,55.907784,93.436771)"
       id="g56">
      <path
         id="path58"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.855 v -7.304 h 0.082 c 0.978,1.575 2.66,2.336 4.1,2.336 4.099,0 5.131,-2.308 5.131,-5.784 v -8.634 H 9.313 v 7.928 c 0,2.308 -0.679,3.448 -2.471,3.448 -2.091,0 -2.987,-1.167 -2.987,-4.018 v -7.358 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,62.708424,98.130201)"
       id="g60">
      <path
         id="path62"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.108,-2.443 1.303,-3.557 3.448,-3.557 1.548,0 2.796,0.951 3.04,1.82 H 9.883 C 8.796,-5.05 6.488,-6.461 3.312,-6.461 c -4.426,0 -7.168,3.04 -7.168,7.384 0,4.208 2.905,7.413 7.168,7.413 4.778,0 7.086,-4.019 6.815,-8.336 z M 6.271,2.444 C 5.918,4.398 5.077,5.431 3.204,5.431 0.759,5.431 0.054,3.53 0,2.444 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,67.085234,95.323811)"
       id="g64">
      <path
         id="path66"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="M 0,0 H 3.666 V -2.606 H 3.72 c 0.706,1.765 2.606,2.987 4.479,2.987 0.272,0 0.598,-0.055 0.843,-0.136 v -3.584 c -0.354,0.081 -0.923,0.135 -1.385,0.135 -2.824,0 -3.802,-2.035 -3.802,-4.506 v -6.327 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,70.753734,96.846541)"
       id="g68">
      <path
         id="path70"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.217,3.611 3.448,4.697 6.598,4.697 2.796,0 6.163,-0.624 6.163,-3.991 v -7.304 c 0,-1.276 0.135,-2.552 0.489,-3.122 H 9.34 C 9.204,-9.286 9.095,-8.824 9.068,-8.363 7.846,-9.639 6.055,-10.1 4.344,-10.1 c -2.661,0 -4.779,1.33 -4.779,4.208 0,3.176 2.39,3.937 4.779,4.263 2.362,0.353 4.561,0.271 4.561,1.846 0,1.657 -1.14,1.9 -2.497,1.9 C 4.941,2.117 3.991,1.521 3.855,0 Z M 8.905,-2.852 C 8.254,-3.421 6.896,-3.448 5.702,-3.666 4.507,-3.91 3.421,-4.317 3.421,-5.729 c 0,-1.439 1.113,-1.792 2.362,-1.792 3.014,0 3.122,2.39 3.122,3.231 z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,76.308954,95.323811)"
       id="g72">
      <path
         id="path74"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.666 v -1.792 h 0.053 c 0.924,1.494 2.445,2.173 4.182,2.173 4.398,0 6.38,-3.557 6.38,-7.549 0,-3.746 -2.063,-7.249 -6.162,-7.249 -1.684,0 -3.286,0.733 -4.209,2.118 H 3.855 v -6.679 H 0 Z m 10.426,-7.059 c 0,2.227 -0.896,4.535 -3.367,4.535 -2.525,0 -3.34,-2.254 -3.34,-4.535 0,-2.28 0.869,-4.453 3.367,-4.453 2.525,0 3.34,2.173 3.34,4.453" />
    </g>
    <path
       id="path76"
       style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth:0.35277775}}
       d="m 82.199714,94.557321 h 1.36031 v -1.12077 h -1.36031 z m 0,5.718179 h 1.36031 v -4.951939 h -1.36031 z" />
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,85.753174,98.130201)"
       id="g78">
      <path
         id="path80"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.108,-2.443 1.303,-3.557 3.448,-3.557 1.548,0 2.797,0.951 3.04,1.82 H 9.883 C 8.797,-5.05 6.488,-6.461 3.312,-6.461 c -4.426,0 -7.168,3.04 -7.168,7.384 0,4.208 2.905,7.413 7.168,7.413 4.778,0 7.086,-4.019 6.815,-8.336 z M 6.271,2.444 C 5.918,4.398 5.077,5.431 3.204,5.431 0.76,5.431 0.054,3.53 0,2.444 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,35.075484,109.72568)"
       id="g82">
      <path
         id="path84"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h -0.054 c -0.897,-1.521 -2.471,-2.172 -4.263,-2.172 -4.235,0 -6.299,3.638 -6.299,7.521 0,3.773 2.091,7.276 6.218,7.276 1.656,0 3.285,-0.706 4.154,-2.091 h 0.054 v 7.06 H 3.665 V -1.792 H 0 Z m -3.366,9.72 c -2.526,0 -3.395,-2.172 -3.395,-4.48 0,-2.199 1.005,-4.507 3.395,-4.507 2.551,0 3.312,2.226 3.312,4.534 0,2.281 -0.815,4.453 -3.312,4.453" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,38.514184,108.21244)"
       id="g86">
      <path
         id="path88"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.108,-2.443 1.303,-3.557 3.448,-3.557 1.548,0 2.796,0.951 3.04,1.82 H 9.883 C 8.796,-5.05 6.488,-6.461 3.312,-6.461 c -4.425,0 -7.168,3.04 -7.168,7.384 0,4.209 2.905,7.412 7.168,7.412 4.778,0 7.086,-4.018 6.815,-8.335 z M 6.272,2.443 C 5.918,4.398 5.077,5.431 3.204,5.431 0.76,5.431 0.054,3.53 0,2.443 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,42.891024,105.40588)"
       id="g90">
      <path
         id="path92"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.666 v -1.955 h 0.081 c 0.977,1.574 2.661,2.335 4.29,2.335 4.099,0 5.131,-2.308 5.131,-5.783 v -8.634 H 9.313 v 7.928 c 0,2.308 -0.679,3.448 -2.471,3.448 -2.09,0 -2.987,-1.168 -2.987,-4.018 v -7.358 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,53.637974,105.27187)"
       id="g94">
      <path
         id="path96"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 4.425,0 7.276,-2.932 7.276,-7.412 0,-4.453 -2.851,-7.385 -7.276,-7.385 -4.398,0 -7.249,2.932 -7.249,7.385 C -7.249,-2.932 -4.398,0 0,0 m 0,-11.892 c 2.633,0 3.421,2.254 3.421,4.48 0,2.253 -0.788,4.507 -3.421,4.507 -2.607,0 -3.394,-2.254 -3.394,-4.507 0,-2.226 0.787,-4.48 3.394,-4.48" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,57.124094,105.40588)"
       id="g98">
      <path
         id="path100"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.639 v -1.901 h 0.054 c 1.004,1.439 2.47,2.281 4.29,2.281 1.765,0 3.339,-0.652 4.1,-2.335 0.813,1.222 2.28,2.335 4.235,2.335 2.987,0 5.131,-1.385 5.131,-5.023 v -9.394 h -3.855 v 7.955 c 0,1.874 -0.163,3.421 -2.362,3.421 -2.172,0 -2.58,-1.792 -2.58,-3.557 v -7.819 H 8.797 v 7.873 c 0,1.63 0.109,3.503 -2.335,3.503 -0.76,0 -2.606,-0.489 -2.606,-3.231 v -8.145 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,65.801934,105.40588)"
       id="g102">
      <path
         id="path104"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.638 v -1.901 h 0.054 c 1.005,1.439 2.471,2.281 4.29,2.281 1.766,0 3.34,-0.652 4.101,-2.335 0.813,1.222 2.28,2.335 4.234,2.335 2.987,0 5.132,-1.385 5.132,-5.023 v -9.394 h -3.855 v 7.955 c 0,1.874 -0.163,3.421 -2.362,3.421 -2.172,0 -2.58,-1.792 -2.58,-3.557 v -7.819 H 8.797 v 7.873 c 0,1.63 0.109,3.503 -2.335,3.503 -0.76,0 -2.607,-0.489 -2.607,-3.231 v -8.145 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,75.562234,108.21244)"
       id="g106">
      <path
         id="path108"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.108,-2.443 1.303,-3.557 3.448,-3.557 1.548,0 2.796,0.951 3.04,1.82 H 9.883 C 8.796,-5.05 6.488,-6.461 3.312,-6.461 c -4.426,0 -7.168,3.04 -7.168,7.384 0,4.209 2.905,7.412 7.168,7.412 4.778,0 7.086,-4.018 6.815,-8.335 z M 6.271,2.443 C 5.918,4.398 5.077,5.431 3.204,5.431 0.76,5.431 0.054,3.53 0,2.443 Z" />
    </g>
    <path
       id="path110"
       style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none', strokeWidth: 0.35277775}}
       d="m 79.977564,103.51894 h 1.36031 v 6.83895 h -1.36031 z" />
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,82.353024,106.92897)"
       id="g112">
      <path
         id="path114"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.217,3.611 3.448,4.697 6.598,4.697 2.796,0 6.163,-0.624 6.163,-3.991 v -7.304 c 0,-1.276 0.135,-2.552 0.489,-3.122 H 9.34 C 9.204,-9.286 9.095,-8.824 9.068,-8.362 7.846,-9.638 6.055,-10.1 4.344,-10.1 c -2.661,0 -4.779,1.331 -4.779,4.209 0,3.176 2.39,3.936 4.779,4.262 2.362,0.353 4.561,0.272 4.561,1.846 0,1.656 -1.14,1.901 -2.497,1.901 C 4.941,2.118 3.991,1.521 3.855,0 Z M 8.905,-2.851 C 8.254,-3.421 6.896,-3.448 5.702,-3.666 4.507,-3.91 3.421,-4.317 3.421,-5.729 c 0,-1.438 1.113,-1.792 2.362,-1.792 3.014,0 3.122,2.39 3.122,3.232 z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,87.908254,105.40588)"
       id="g116">
      <path
         id="path118"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.666 v -1.955 h 0.081 c 0.977,1.574 2.661,2.335 4.29,2.335 4.099,0 5.131,-2.308 5.131,-5.783 v -8.634 H 9.313 v 7.928 c 0,2.308 -0.679,3.448 -2.471,3.448 -2.09,0 -2.987,-1.168 -2.987,-4.018 v -7.358 H 0 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,97.122494,109.72568)"
       id="g120">
      <path
         id="path122"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h -0.055 c -0.896,-1.521 -2.47,-2.172 -4.262,-2.172 -4.235,0 -6.299,3.638 -6.299,7.521 0,3.773 2.091,7.276 6.218,7.276 1.655,0 3.285,-0.706 4.154,-2.091 h 0.054 v 7.06 H 3.665 V -1.792 H 0 Z m -3.366,9.72 c -2.526,0 -3.395,-2.172 -3.395,-4.48 0,-2.199 1.005,-4.507 3.395,-4.507 2.551,0 3.311,2.226 3.311,4.534 0,2.281 -0.814,4.453 -3.311,4.453" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,100.56123,108.21244)"
       id="g124">
      <path
         id="path126"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 c 0.108,-2.443 1.303,-3.557 3.448,-3.557 1.548,0 2.796,0.951 3.04,1.82 H 9.883 C 8.796,-5.05 6.488,-6.461 3.312,-6.461 c -4.426,0 -7.168,3.04 -7.168,7.384 0,4.209 2.905,7.412 7.168,7.412 4.778,0 7.086,-4.018 6.815,-8.335 z M 6.271,2.443 C 5.918,4.398 5.077,5.431 3.204,5.431 0.759,5.431 0.054,3.53 0,2.443 Z" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,104.93804,105.40588)"
       id="g128">
      <path
         id="path130"
         style={{fill: '#062a30', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
         d="m 0,0 h 3.666 v -1.955 h 0.081 c 0.977,1.574 2.66,2.335 4.29,2.335 4.099,0 5.131,-2.308 5.131,-5.783 v -8.634 H 9.313 v 7.928 c 0,2.308 -0.679,3.448 -2.471,3.448 -2.091,0 -2.987,-1.168 -2.987,-4.018 v -7.358 H 0 Z" />
    </g>
  </g>
</svg>

}

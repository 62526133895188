import * as React from 'react';
import classNames from 'classnames'
import styles from './button.module.scss';
import {OButton} from "./OButtons"


export const OSignup = (props) => {
  return <div className={classNames(styles.signupinline, 'row', 'justify-content-center')}>
    <div className="col-12 col-md-6 col-lg-4">
      <OButton extraclass={styles.blue} target={'/aanmelden'} title={'Nu aanmelden'} />
    </div>
 </div>
}

import * as React from "react";
import {connect} from 'react-redux';

import {IData, IGroupItems} from "../../reducers/dataReducer"

import {O_ID_Sort} from "../../tools/tools"

import {OButton} from "../buttons/OButtons"

import styles from './issues.module.scss';

interface State {}
interface parentProps {
  gid: number
  items: IGroupItems[]
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class GroupItemList extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    if (!this.props.items)
      return <div></div>

    return <div className="container">
      <div className="row">
        {(this.props.gid == 1)? this.list() : this.darklist()}
      </div>
    </div>
  }

  list() {
    return this.props.items.map((item) => {
      return <div className={`${styles.issues} col-12 col-md-4 col-lg-3 col-xl-3`} key={item.id}>
        <OButton target={`/${item.slug}`} title={item.title} extraclass={styles.button} />
      </div>
    })
  }

  darklist() {
    return this.props.items.map((item) => {
      return <div className={`${styles.bigbutton} col-12 col-lg-6 col-xl-4`} key={item.id}>
        <OButton target={`/${item.slug}`} title={item.title} extraclass={styles.button} />
      </div>
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(GroupItemList)

import * as React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from 'classnames'
import {IData} from "../../reducers/dataReducer"

import Header from './Header'
import Privacy from './Privacy'
import {OLabel, ORow, OSubmit, OWarning} from './FormHelpers'
import Topnav from "../buttons/Topnav"

import {O_ID_Sort} from '../../tools/tools'

import styles from './appointment.module.scss'

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { FormGroup, Label, Input, Col } from 'reactstrap';

interface State {
  appointment: {
    date: string;
    reason: string;
    therapist: number;
    name: string;
    city: string;
    email: string;
    phone: string;
  },
  show_warning: boolean;
}

interface parentProps {
  history: any;
  background: string;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Cancel extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      appointment: {
        date: "",
        reason: "",
        name: "",
        city: "",
        email: "",
        phone: "",
        therapist: 0,
      },
      show_warning: false,
    }
  }


  render() {
    return (
      <div className="ocontent">
        <Helmet>
          <title>Afspraak afzeggen - Fitness Ten Boer</title>
          <meta name="description" content={`Afspraak afzeggen - Fitness Ten Boer`} />
        </Helmet>

        <Topnav background={this.props.background}/>

        <div className={classNames(styles.formHolder, "row justify-content-md-center")}>
          <div className="col-12">
            {this.form()}
          </div>
        </div>
      </div>
      )
  }

  form() {
    const a = this.state.appointment
    return (
      <div className={classNames(styles.form, "container")}>
       <ORow title="Afspraakdatum">
          <Input
              invalid={a.date.trim().length == 0}
              type="text"
              name="date"
              value={a.date}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Reden afmelding">
          <Input
              type="textarea"
              name="reason"
              value={a.reason}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Welke therapeut">
          <Input
              invalid={a.therapist < 1}
              type="select"
              name="therapist"
              value={a.therapist}
              onChange={(e) =>this.handleInputChange(e)}
              children={
                [<option key={-1} value={-1}>Geen voorkeur</option>,
                ...O_ID_Sort(this.props.data.humans.byIds, "surname").map((t) => {
                  if (t.supportstaff == false)
                    return <option key={t.id} value={t.id}>{t.name}</option>
                }
                  )]}
          />
        </ORow>


       <ORow title="Naam">
          <Input
              invalid={a.name.trim().length == 0}
              type="text"
              name="name"
              value={a.name}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Woonplaats">
          <Input
              invalid={a.city.trim().length == 0}
              type="text"
              name="city"
              value={a.city}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Email">
          <Input
              invalid={a.email.trim().length == 0}
              type="text"
              name="email"
              value={a.email}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

       <ORow title="Telefoonnummer">
          <Input
              invalid={a.phone.trim().length == 0}
              type="text"
              name="phone"
              value={a.phone}
              onChange={(e) =>this.handleInputChange(e)}
          />
        </ORow>

        {this.state.show_warning? <OWarning /> : ''}

        <OSubmit click={() => this.submit()} />
        <Privacy />
      </div>
    )
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newstate = {
      ...this.state.appointment,
      [name]: value,
    }

    this.setState({
      appointment: {
        ...newstate,
      },
      show_warning: false
    });
  }

  validate() {
    const a = this.state.appointment
    return a.name.trim().length > 0 &&
    a.date.trim().length > 0 &&
    a.reason.trim().length > 0 &&
    a.city.trim().length > 0 &&
    a.email.trim().length > 0 &&
    a.phone.trim().length > 0 &&
    a.therapist > 0
  }

  submit() {
    if (!this.validate()){
      this.setState({
        show_warning: true
      })
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    let that = this

    axios.post("/contact/cancel",{
      cancel: this.state.appointment
    }, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }).then(function(result) {
      console.log("worked")
      that.props.history.push('/afzegging_verzonden')
    }).catch(function (error) {
      if (error.response) {
        console.log('Error', error.message);
      }
    })
  }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cancel));

import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';

import { actionTypes } from "./actionTypes"
import { IData } from "../reducers/dataReducer"

export function initData(data: IData) {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_INIT, payload: {data: data}})
  }
}

export function loadData() {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_LOAD});

    axios.get("/data.json")
      .then((response) => {
        dispatch({type: actionTypes.OMMEN_LOADED, payload: response.data})
      });
    }
}

export function loadPage(slug) {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_PAGE_LOAD});

    axios.get(`/page/${slug}.json`)
      .then((response) => {
        dispatch({type: actionTypes.OMMEN_PAGE_LOADED, payload: response.data})
      });
    }
}

export function loadBlog(id) {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_BLOG_LOAD});

    axios.get(`/getblog/${id}.json`)
      .then((response) => {
        dispatch({type: actionTypes.OMMEN_BLOG_LOADED, payload: response.data})
      });
    }
}

export function loadIssue(part) {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_ISSUE_LOAD});

    axios.get(`/issue/${part}.json`)
      .then((response) => {
        dispatch({type: actionTypes.OMMEN_ISSUE_LOADED, payload: response.data})
      });
    }
}


export function loadGroup(gid: number) {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_GROUP_LOAD});

    axios.get(`/group/${gid}.json`)
      .then((response) => {
        dispatch({type: actionTypes.OMMEN_GROUP_LOADED, payload: response.data})
      });
    }
}

export function setColor(color: string, id: number) {
  return function(dispatch) {
    dispatch({type: actionTypes.OMMEN_SET_COLOR, payload:{ color: color, active_loc: id}})
  }
}


import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import {IData} from "../../reducers/dataReducer"

import placeholder from "../../images/placeholder.png"
import styles from './humans.module.scss'

interface State {}

interface parentProps {
  ids: number[];
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Facelist extends React.Component<Props, State> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        {this.props.ids.map((id) => this.human(id))}
      </div>
      )
  }

  human(id) {
    let human = this.props.data.humans.byIds[id]
    let img = (human.photo_sm)? human.photo_sm : placeholder

    return <div key={id} className="col-12 col-md-6 col-lg-4 col-xl-3">
      <Link className={styles.namelink} to={`/over/${human.slug}`}>
        <div className={styles.imgHolder}>
            <img src={img} className="img-fluid"/>

        </div>
        <div className={styles.nameblock}>{human.name}</div>
      </Link>
    </div>
  }

 }

 export default connect(mapStateToProps, mapDispatchToProps)(Facelist);

import * as React from 'react';

import classNames from 'classnames'
import styles from './appointment.module.scss'

export const ContentPage = (props) => {
  return <div className="container ocontent">
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-8 content pl-0 pr-0">
        <h1 className="pagetitle">{props.title}</h1>
        {props.children}
      </div>
    </div>
  </div>
}

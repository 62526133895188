import * as React from 'react';
import {connect} from 'react-redux';

import {Link} from "react-router-dom";

import classNames from 'classnames'

import {ILocationId, ISpecialtyId} from "../../reducers/dataReducer"
import {O_Format_text} from "../../tools/tools"

import Topnav from "../buttons/Topnav"
import Facelist from "../humans/Facelist"

import styles from './location.module.scss';

interface State {}

interface parentProps {
  loc_id: number;
  opt_content?: string;
}

interface StoreProps {
  locations: {
    byIds: ILocationId
  },
  specialties: {
    byIds: ISpecialtyId
  }
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    locations: state.data.locations,
    specialties: state.data.specialties,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class OLocationDetails extends React.Component<Props, State> {

  render() {

    const loc = this.props.locations.byIds[this.props.loc_id]

    return <div>
      <div className="container ocontent">
        <h1 className={styles.title}>{loc.title}</h1>
        <div className="row">
          <div className="col col-md-6 col-lg-8 brood">
            <div>
              <span className={classNames(styles.subtitle, "bold")} style={{color: loc.color}}>{loc.name}</span><br/>

              {this.opt_content()}

              {this.note(loc)}

            </div>

            {this.walkin(loc)}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {this.specialtyList(loc)}
          </div>
        </div>

        <Facelist ids={loc.humans}/>

      </div>
    </div>
  }

  opt_content() {
    if (this.props.opt_content.length == 0)
      return <span></span>
    else
      return <div className={styles.optcontent}>{this.props.opt_content}</div>

  }
openings(loc) {
    return <div className={styles.openhours}>
      <span className={classNames(styles.subtitle, "bold")}>Openingstijden </span><br/>
      <table>
        <tbody>
          {this.otime(1, loc.o_mo_open, loc.o_mo_close)}
          {this.otime(2, loc.o_tu_open, loc.o_tu_close)}
          {this.otime(3, loc.o_we_open, loc.o_we_close)}
          {this.otime(4, loc.o_th_open, loc.o_th_close)}
          {this.otime(5, loc.o_fr_open, loc.o_fr_close)}
          {this.otime(6, loc.o_sa_open, loc.o_sa_close)}
          {this.otime(0, loc.o_su_open, loc.o_su_close)}
        </tbody>
      </table>
    </div>
  }

  otime(day, open, close) {
    const daylist = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag']

    const now = new Date()
    const daynr = now.getDay()

    let dayClass = (daynr == day)? styles.active : ''

    if (open === "00:00")
      return <tr className={classNames(dayClass)}>
        <td className={styles.day}>{daylist[day]}</td>
        <td className={styles.closed} colSpan={3}>Gesloten</td>
      </tr>

    return <tr className={dayClass}>
      <td className={styles.day}>{daylist[day]}</td>
      <td>{open}</td>
      <td> - </td>
      <td>{close}</td>
    </tr>
  }


  walkin(loc) {
    if (loc.walkin)
      return <div className={styles.walkin}>
        <span className={classNames(styles.subtitle, "bold")}>Inloopspreekuur</span><br/>
        <p dangerouslySetInnerHTML={{ __html: O_Format_text(loc.walkin) }}></p>
      </div>
    return <span></span>
  }

  note(loc) {
    if (loc.note)
      return <div className={styles.note}>
        *{loc.note}
      </div>
    return <span></span>
  }

  specialtyList(loc){
    if (loc.specialties.length == 0 )
      return <div></div>

    return <div className={styles.speclist}>
      {loc.specialties.map((id) => {
          let spec = this.props.specialties.byIds[id]
          return <Link to={`/specialisatie/${spec.slug}`} key={id}><div >{spec.title}</div></Link>
        }
      )}
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OLocationDetails);

import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import classNames from 'classnames'

import {IData, IPage} from "../../reducers/dataReducer"
import {statusTypes} from "../../actions/actionTypes"

import {loadBlog} from "../../actions/ommenActions"


import styles from './blog.module.scss';

interface State {
  id: number;
}

interface parentProps {
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {
  loadBlog: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  loadBlog: (id: number) => { dispatch(loadBlog(id))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Blog extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let id = props.match.params.id
    if(id != state.id){
      let blog = props.data.blogs.byIds[id]
      if (!blog)
        props.loadBlog(id)
      return {
        id: id
      }
    }

    return null;
  }

  componentDidMount() {
    this.props.loadBlog(this.state.id)
  }


  render() {
    let blog = this.props.data.blogs.byIds[this.state.id]
    if (!blog)
      return <div></div>

    if (!this.haveData()) {
      return <div>Loading...</div>
    }

    return this.renderBlog(blog)
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

  renderBlog(blog) {
    const content = <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
    const title = blog.title


    return <div className={styles.blog}>
      <Helmet>
        <title>{title} - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`${title} - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <div className="container ocontent">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-8 content pl-0 pr-0">
            <h1 className={classNames("pagetitle", styles.blogtitle)}>{title}</h1>
            <div className={classNames(styles.subtitle)}><span className={styles.username}>{blog.user}</span> op {blog.date}</div>

            <div>
              <img src={blog.background} className="img-fluid"/>
            </div>

            <div className={styles.blogtext}>
              {content}
            </div>
          </div>
        </div>
      </div>

    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)

import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames"

import {IData, IIssue} from "../../reducers/dataReducer"
import {statusTypes} from "../../actions/actionTypes"

import {loadIssue, loadGroup} from "../../actions/ommenActions"
import Topnav from "../buttons/Topnav"
import {ContentPage} from "../pages/ContentPage"
import GroupItemList from "./GroupItemList"

import styles from './issues.module.scss';

interface State {
  part: string;
}

interface parentProps {
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {
  loadIssue: Function;
  loadGroup: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  loadIssue: (part: string) => { dispatch(loadIssue(part))},
  loadGroup: (gid: number) => { dispatch(loadGroup(gid))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Issues extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      part: this.props.match.params.part,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let part = props.match.params.part
    if(part != state.part){
      let page = props.data.pages.bySlugs[part]
      if (!page)
        props.loadIssue(part)
      return {
        part: part
      }
    }

    return null;
  }


  componentDidMount() {
    this.props.loadIssue(this.state.part)
    this.props.loadGroup(1)
  }

  render() {
    let group = this.props.data.groups.byIds[1]
    let issue = this.props.data.issues.byParts[this.state.part]
    if (!issue)
      return <div></div>

    if (!this.haveData()) {
      return <div>Loading...</div>
    }

    return this.renderPage(issue, group)

  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

  renderPage(issue:IIssue, group) {
    return <div>

      <Helmet>
        <title>{issue.title} - Fitness Ten Boer</title>
        <meta name="description" content={`${issue.title} - Fitness Ten Boer`} />
      </Helmet>

      <Topnav />
      <div className="ocontent">
        <ContentPage title={issue.title}>
          <div dangerouslySetInnerHTML={{ __html: issue.content }}></div>
        </ContentPage>
        <GroupItemList gid={1} items={group.items} />
      </div>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Issues)

import * as React from "react";
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux';

import {Link} from "react-router-dom";

import classNames from "classnames"
import {OButton} from "./OButtons"

import styles from './button.module.scss';

interface State {}

interface parentProps {
  background?: string;
  title?: string;
  active?: string;
  noPadding?: boolean;
  location: any;
}

interface StoreProps {}

interface DispatchProps {}

interface Mitem {
  path: string;
  color: string;
  title: string;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});
type Props = StoreProps & DispatchProps & parentProps

export class Topnav extends React.Component<Props, State> {
  items: Mitem[]
  constructor(props) {
    super(props);

    this.state = {}
    this.items = [
      { path: '/fitness', color: styles.orange, title: 'Fitness'},
      { path: '/hulp-bij-goede-voeding', color: styles.green, title: 'Voeding en leefstijl'},
      { path: '/bewegen-met-pijn', color: styles.pink, title: 'Bewegen met pijn'},
    ]
  }

  render() {
    let headStyle = {}
    let topclass = styles.headWithoutImage

    if (this.props.background) {
      headStyle = {
        backgroundImage: `url(${this.props.background})`,
      }
      topclass = styles.headWithImage
    }

    let hasTitle = ''
    if (this.props.title)
      hasTitle = styles.hasTitle

    return <div style={headStyle} className={classNames(topclass, hasTitle)}>
        <div className={classNames("container", {[`${styles.noPadding}`]: this.props.noPadding})}>
          {this.normal()}
          {this.title()}
          {this.signup()}
        </div>
      </div>
  }

  normal() {
    let found = -1

    this.items.forEach((item, index) => {
      if (this.props.location.pathname === item.path)
        found = index
    })

    return <div className={classNames(styles.topnav, 'row')}>
      {this.items.map((item, index) => {
        let c = [item.color]
        if (found > -1 && index != found)
          c.push(styles.trans)

        return <div className="col-12 col-md-4">
          <OButton extraclass={c} target={item.path} title={item.title} />
        </div>
      })}
    </div>
  }

  title() {
    if (this.props.title)
      return <div><h1 className={styles.herotitle}>{this.props.title}</h1></div>
    else
      return ''
  }

        // <div className="col-12 col-md-6 col-lg-4">
        //   <OButton remote={true} extraclass={styles.blue} target={'https://www.supersaas.nl/schedule/fitnesstenboer/FitnessTenBoer'} title={'Je plaats reserveren'} />
        // </div>
  signup() {
    if (this.props.title)
      return <div className={classNames(styles.topnav, styles.signup, 'row', 'justify-content-center')}>
        <div className="col-12 col-md-6 col-lg-4">
          <OButton extraclass={styles.blue} target={'/aanmelden'} title={'Word lid!'} />
        </div>
      </div>
    else
      return ''

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topnav));

import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import {IData, IPage} from "../../reducers/dataReducer"
import {statusTypes} from "../../actions/actionTypes"

import {loadPage} from "../../actions/ommenActions"

import Topnav from "../buttons/Topnav"
import {ContentPage} from "./ContentPage"
import OLocation from "../location/OLocation"
import OLocationDetails from "../location/OLocationDetails"
import Facelist from "../humans/Facelist"
import Specialties from "../specialties/Specialties"
import Trainings from "../trainings/Trainings"
import Contact  from "../appointment/Contact"
import Appointment from "../appointment/Appointment"
import Cancel from "../appointment/Cancel"
import {OSignup} from "../buttons/Signup"


import styles from './styles.css';

interface State {
  slug: string;
}

interface parentProps {
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {
  loadPage: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  loadPage: (slug: string) => { dispatch(loadPage(slug))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Page extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let slug = props.match.params.slug
    if(slug != state.slug){
      let page = props.data.pages.bySlugs[slug]
      if (!page)
        props.loadPage(slug)
      return {
        slug: slug
      }
    }

    return null;
  }

  componentDidMount() {
    this.props.loadPage(this.state.slug)
  }

  render() {
    let page = this.props.data.pages.bySlugs[this.state.slug]
    if (!page)
      return <div></div>

    if (!this.haveData()) {
      return <div>Loading...</div>
    }

    return this.renderPage(page)
  }



  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }


  renderPage(page) {
    const content = <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
    const title = page.title

    switch (page.template) {

      case "specialisaties-overzicht":

        return <Specialties content={content}/>

      case "trainingen-overzicht":
        return <Trainings content={content}/>

      case "contact":
        return this.contactPage(title, content, page.background)
        break;

      case "afspraak-maken":
        return this.afspraakPage(title, content, page.background)
        break;

      case "afspraak-afzeggen":
        return this.cancelPage(title, content, page.background)
        break;

      case "over-ons":
        return this.aboutus(title, content)
        break;

      case "content":
      case "niet-gevonden":
        return this.content(title, content, page.background)
        break;

      case "content-geen-cta":
        return this.content_no_cta(title, content, page.background)
        break;


      case "klachten":
        return this.issues(title, content, page.background)
        break;

      case "locatie-overzicht":
        return this.locations(title, content)
        break;

      case "homepage":
        return '';
        break

      default:
        return <div>Template {page.template} missing</div>
        break;
    }
  }

  content(title, content, background) {
    return <div>

      <Helmet>
        <title>{title} - Fitness Ten Boer</title>
        <meta name="description" content={`${title} - Fitness Ten Boer`} />
      </Helmet>

      <Topnav background={background}/>
      <ContentPage title={title}>
        {content}
      </ContentPage>
      <OSignup />
    </div>
  }

  content_no_cta(title, content, background) {
    return <div>

      <Helmet>
        <title>{title} - Fitness Ten Boer</title>
        <meta name="description" content={`${title} - Fitness Ten Boer`} />
      </Helmet>

      <Topnav background={background}/>
      <ContentPage title={title}>
        {content}
      </ContentPage>

    </div>
  }

  locations(title, content) {
    const loc = this.props.data.locations.byIds[1]
    return <div>

      <Helmet>
        <title>{title} - Fitness Ten Boer</title>
        <meta name="description" content={`${title} - Fitness Ten Boer`} />
      </Helmet>
      <Topnav />
      <div className="container ocontent">
        <OLocationDetails loc_id={loc.id} opt_content={content}/>
      </div>
    </div>
  }

  afspraakPage(title, content, background) {
    return <Appointment background={background} />
  }

  cancelPage(title, content, background) {
    return <Cancel background={background} />
  }

  contactPage(title, content, background) {
    return <div>

      <Helmet>
        <title>Contact - Fitness Ten Boer</title>
        <meta name="description" content={`Contact - Fitness Ten Boer`} />
      </Helmet>


      <Topnav background={background}/>
      <div className="container ocontent">
        <Contact />
      </div>
    </div>
  }

  aboutus(title, content){
    return <div>

      <Helmet>
        <title>Over ons - Fitness Ten Boer</title>
        <meta name="description" content={`Over ons - Fitness Ten Boer`} />
      </Helmet>

      <Topnav />
      <div className="container">
        <div className="ocontent row justify-content-md-center">
          <div className="col content pl-0 pr-0">
            <ContentPage title={title}>
              {content}
            </ContentPage>
            <Facelist ids={this.props.data.humans.ordered}/>
          </div>
        </div>
      </div>
    </div>
  }



  issues(title, content, background) {
    return <div>
      <Helmet>
        <title>Klachten - Fitness Ten Boer</title>
        <meta name="description" content={`$Klachten - Fitness Ten Boer`} />
      </Helmet>

      <Topnav background={background}/>
      <ContentPage title={title}>
        {content}
      </ContentPage>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)

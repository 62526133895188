import * as React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import classNames from "classnames"

import {IData, IGroup} from "../../reducers/dataReducer"
import {statusTypes} from "../../actions/actionTypes"

import {loadGroup} from "../../actions/ommenActions"
import Topnav from "../buttons/Topnav"
import {ContentPage} from "../pages/ContentPage"
import GroupItemList from "./GroupItemList"
import {OSignup} from "../buttons/Signup"

//import IssueList from "./IssueList"

import styles from './issues.module.scss';

interface State {
  gid: number;
}

interface parentProps {
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {
  loadGroup: Function;
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({
  loadGroup: (gid: number) => { dispatch(loadGroup(gid))},
});

type Props = StoreProps & DispatchProps & parentProps

export class Issue extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      gid: this.props.match.params.gid,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let gid = props.match.params.gid
    if(gid != state.gid){
      let page = props.data.pages.bySlugs[gid]
      if (!page)
        props.loadGroup(gid)
      return {
        gid: gid
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.loadGroup(this.state.gid)
  }

  render() {

    if (!this.haveData()) {
      return <div>Loading...</div>
    }

    let group = this.props.data.groups.byIds[this.state.gid]
    if (!group)
      return <div></div>


    return this.renderPage(group)
  }
  renderPage(group) {
    return <div>
      <Helmet>
        <title>{group.title} - Fysiotherapie Den Ommelanden</title>
        <meta name="description" content={`${group.title} - Fysiotherapie Den Ommelanden`} />
      </Helmet>

      <Topnav background={group.background}/>

      <ContentPage title={group.title}>
        <div dangerouslySetInnerHTML={{ __html: group.content }}></div>
      </ContentPage>
      <GroupItemList gid={group.id} items={group.items} />

      <OSignup />

    </div>
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(Issue)

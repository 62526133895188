
// sort object keyed by ID, based on given subkey
export function O_ID_Sort(obj: Object, key: string) {
  return Object.keys(obj).map((id) => obj[id]).sort((a, b) => {
    if(a[key] < b[key])
      return -1
    if (a[key] > b[key])
      return 1
    return 0
  })
}

export function O_Find_By(obj: Object, key: string, needle: string) {
  let found_id = -1

  Object.keys(obj).forEach((id) => {
    if (obj[id][key] == needle) {
      found_id = obj[id].id
    }
  })

  return found_id
}

export function O_Format_text(text: string) {
  return text.replace(/\n/g, "<br />")
}

export function O_Pick_random(list) {
  let ids = new Array();

  Object.keys(list).forEach((id) => {
    ids.push(id)
  });

  const random = ids[Math.floor(Math.random() * ids.length)];
  return list[random]
}

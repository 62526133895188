import * as React from "react";
import {connect} from 'react-redux';
import { withRouter } from "react-router";


import {statusTypes} from "../actions/actionTypes"
import {IData} from "../reducers/dataReducer"

interface State {
}

interface parentProps {
  children: any;
  match: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class LoadBarrier extends React.Component<Props, State> {

  render() {

    if (!this.haveData()) {
      return <div></div>
    }

   return this.props.children
  }

  haveData() {
    const s = this.props.data.status
    return (s == statusTypes.LOADED || s == statusTypes.UPDATING)
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadBarrier));

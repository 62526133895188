import * as React from "react";
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import classNames from "classnames";
import styles from './header.module.scss';

import {IData} from "../../reducers/dataReducer"

import {SVGHamburger} from "./Logo"
import logo from  '../../images/logo.svg'

interface State {
  opened: boolean;
}

interface parentProps {
  location: any;
}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class Header extends React.Component<Props, State> {

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    }
  }

  render() {
    return <div className="container-fluid">
      <div className={styles.onav} style={{display: 'flex'}}>
        <Link to="/" className={styles.logo}><img src={logo}/></Link>

        <div onClick={() => this.toggleMenu()} className={classNames(styles.toggle)} aria-label="Toggle navigation">
          <SVGHamburger color={"#d8006d"}/>
        </div>

        <div className={classNames(styles.menuHolder,{[`${styles.open}`]: this.state.opened})}>
          <ul className={classNames(styles.navbarNav, "ml-auto")}>
            {this.items()}
          </ul>
        </div>
      </div>
    </div>
  }

  items() {
    let path = this.props.location.pathname
    let active = "/"

    switch (path.split('/')[1]) {
      case "specialisaties":
      case "specialisatie":
        active = "specialisaties"
        break;

      case "trainingen":
      case "training":
        active = "trainingen"
        break;

      case "verwijzers":
        active = "verwijzers"
        break;

      case "over-ons":
      case "over":
        active = "over-ons"
        break;

      case "locatie":
        active = "locatie"
        break;

      case "contact":
      case "afspraak":
        active = "contact"
        break;

      default:
        active = "home"
        break;
    }

    return this.props.data.menu.map((item) => {
        let style = {
          borderStyle: 'solid',
          borderColor: '#d8006d',
          borderWidth: ("/" + active == item.link)?'0px 0px 1px 0px' : "0px"
        }

        return <li key={item.id}><Link onClick={() => this.clicked()} style={style} to={item.link}>{item.title}</Link></li>
      }
    )
  }

  toggleMenu() {
    this.setState({
      opened: !this.state.opened,
    })
  }

  clicked() {
    this.setState({
      opened: false,
    })
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));


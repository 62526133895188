import * as React from 'react';
import {Link} from "react-router-dom";
import classNames from 'classnames'

import styles from './appointment.module.scss'

const Privacy = (props) => {
  return (
    <div className={classNames(styles.privacy, "row")}>
      <div className="col-0 col-md-4"></div>
      <div className="col-12 col-md-8">
        Persoonsgegevens die u verstrekt gebruiken wij uitsluitend om u zo goed mogelijk van dienst te zijn. Zie hiervoor onze <Link to="/privacy-klachtenregeling">Privacyverklaring</Link>. Door dit formulier te versturen gaat u hiermee akkoord.
      </div>
    </div>
  )
}

export default Privacy

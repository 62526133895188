export enum actionTypes {
  OMMEN_INIT,
  OMMEN_LOAD,
  OMMEN_LOADED,
  OMMEN_PAGE_LOAD,
  OMMEN_PAGE_LOADED,
  OMMEN_ISSUE_LOAD,
  OMMEN_ISSUE_LOADED,
  OMMEN_SET_COLOR,
  OMMEN_GROUP_LOAD,
  OMMEN_GROUP_LOADED,
  OMMEN_BLOG_LOAD,
  OMMEN_BLOG_LOADED,
}

export enum statusTypes {
  INIT,
  LOADING,
  LOADED,
  UPDATING,
}

import * as React from "react";
import {connect} from 'react-redux';

import {IData} from "../../reducers/dataReducer"

import {O_ID_Sort} from "../../tools/tools"
import {OButton} from "../buttons/OButtons"

import styles from './styles.module.css';

interface State {}
interface parentProps {}

interface StoreProps {
  data: IData;
}

interface DispatchProps {}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}

const mapDispatchToProps = dispatch => ({});

type Props = StoreProps & DispatchProps & parentProps

export class SpecialtyList extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return <div className="row">
      {this.specialtylist()}
    </div>
  }

  specialtylist() {
    let list = O_ID_Sort(this.props.data.specialties.byIds, "title")
    return list.map((spec) => {
      return <div className={`${styles.specialty} col-12 col-lg-6 col-xl-4`} key={spec.id}>
        <OButton target={`/specialisatie/${spec.slug}`} title={spec.title} />
      </div>
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialtyList)
